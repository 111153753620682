import { render, staticRenderFns } from "./default.vue?vue&type=template&id=8aa6b61c&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=8aa6b61c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8aa6b61c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageProgress: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/PageProgress.vue' /* webpackChunkName: "components/page-progress" */).then(function(m) { return m['default'] || m }) },Header: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/Header.vue' /* webpackChunkName: "components/header" */).then(function(m) { return m['default'] || m }) },Button: function () { return import('/home/runner/work/fjor-wellness-funnel/fjor-wellness-funnel/components/_atoms/Button.vue' /* webpackChunkName: "components/button" */).then(function(m) { return m['default'] || m }) }})
