import amplitude from 'amplitude-js'
import { doc, collection } from 'firebase/firestore'

export default (ctx, inject) => {
  const exitCallback = () => {
    amplitude.getInstance().logEvent('Funnel Closed', {
      url: window.location.href,
      page_id: ctx?.route?.params?.page_id || 'start_page'
    })
  }

  amplitude
    .getInstance()
    .init(ctx.$config.amplitude_api_key, ctx?.route?.query?.uid || null, {
      // optional configuration options
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      includeFbclid: true,
      includeGclid: true,
      saveParamsReferrerOncePerSession: false,
      deviceId:
        ctx?.route?.query?.lead_id ||
        doc(collection(ctx.$firestore, 'users')).id,
      onExitPage: exitCallback
    })

  inject('ampGetInstance', () => {
    return amplitude.getInstance().options.deviceId
  })

  inject('ampSetUserId', uid => {
    return amplitude.getInstance().setUserId(uid)
  })

  inject('ampLogEvent', ({ event, properties, timestamp, user_properties }) => {
    if (properties)
      timestamp
        ? amplitude
            .getInstance()
            .logEventWithTimestamp(event, properties, timestamp)
        : amplitude.getInstance().logEvent(event, properties)
    if (user_properties)
      amplitude.getInstance().setUserProperties(user_properties)
  })
}
