import Vue from 'vue'

async function load(api_key) {
  const script = document.createElement('script')
  script.async = true
  script.src = 'https://js.recurly.com/v4/recurly.js'

  const style = document.createElement('link')
  style.rel = 'stylesheet'
  style.href = 'https://js.recurly.com/v4/recurly.css'

  const firstScript = document.getElementsByTagName('script')[0]
  await Promise.all([
    new Promise((resolve, reject) => {
      script.onload = resolve
      script.onerror = reject
      firstScript.parentNode.insertBefore(script, firstScript)
    }),
    new Promise((resolve, reject) => {
      style.onload = resolve
      style.onerror = reject
      document.head.appendChild(style)
    })
  ])
  window.recurly.configure(api_key)
  return window.recurly
}

function arraysEqual(a, b) {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

const VueLazyRecurly = {
  install(_Vue, { api_key, $logEvent }) {
    Object.defineProperty(_Vue.prototype, '$recurly', {
      enumerable: true,
      get: () => window.recurly
    })

    let loading = null
    Object.defineProperty(_Vue.prototype, '$recurlyLoad', {
      enumerable: true,
      get: () => async () => {
        if (window.recurly) return window.recurly
        if (loading) return loading
        try {
          loading = load(api_key)
          return await loading
        } catch (err) {
          console.error(err)
          throw err
        } finally {
          loading = null
        }
      }
    })

    let plans_loading = null
    let plans_loaded = null
    Object.defineProperty(_Vue.prototype, '$recurlyPlansLoad', {
      enumerable: true,
      get:
        () =>
        async ({ plans, currency = 'USD', coupon }) => {
          if (!window.recurly) return console.error('Recurly not found')

          plans_loaded = plans
          if (plans_loading && arraysEqual(plans_loaded, plans))
            return plans_loading

          const now = Date.now()

          await $logEvent({
            amp: {
              type: 'Recurly Prices Loading Started',
              properties: {
                plans: JSON.stringify(plans)
              }
            }
          })

          try {
            plans_loading = Promise.all(
              plans.map(async planId => {
                const pricing = window.recurly.Pricing.Subscription()
                await new Promise(resolve => {
                  pricing
                    .currency(currency)
                    .plan(planId)
                    .coupon(coupon)
                    .done(resolve)
                })

                const { items, price } = pricing
                return { items, price }
              })
            ).then(data => {
              $logEvent({
                amp: {
                  type: 'Recurly Prices Loading Completed',
                  properties: {
                    execute_time: Date.now() - now,
                    plans: JSON.stringify(plans)
                  }
                }
              })

              return data
            })

            return await plans_loading.then(data => {
              plans_loading = data
              return data
            })
          } catch (err) {
            console.error(err)
            plans_loading = null
            throw err
          }
        }
    })
  }
}

export default ({ $config, $logEvent }) =>
  Vue.use(VueLazyRecurly, {
    api_key: $config.recurly_api_key,
    $logEvent
  })
