import { wrapFunctional } from './utils'

export const NuxtDynamic = import('../../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => wrapFunctional(c.default || c))
export const V005StartPage = import('../../components/funnels/v005/StartPage.vue' /* webpackChunkName: "components/v005-start-page" */).then(c => wrapFunctional(c.default || c))
export const V006StartPage = import('../../components/funnels/v006/StartPage.vue' /* webpackChunkName: "components/v006-start-page" */).then(c => wrapFunctional(c.default || c))
export const Billing = import('../../components/Paywall/Billing.vue' /* webpackChunkName: "components/billing" */).then(c => wrapFunctional(c.default || c))
export const BillingTabs = import('../../components/Paywall/BillingTabs.vue' /* webpackChunkName: "components/billing-tabs" */).then(c => wrapFunctional(c.default || c))
export const MoneyBack = import('../../components/Paywall/MoneyBack.vue' /* webpackChunkName: "components/money-back" */).then(c => wrapFunctional(c.default || c))
export const PaywallBlock = import('../../components/Paywall/PaywallBlock.vue' /* webpackChunkName: "components/paywall-block" */).then(c => wrapFunctional(c.default || c))
export const PaywallOffer = import('../../components/Paywall/PaywallOffer.vue' /* webpackChunkName: "components/paywall-offer" */).then(c => wrapFunctional(c.default || c))
export const SecureImages = import('../../components/Paywall/SecureImages.vue' /* webpackChunkName: "components/secure-images" */).then(c => wrapFunctional(c.default || c))
export const SuccessPayment = import('../../components/Paywall/SuccessPayment.vue' /* webpackChunkName: "components/success-payment" */).then(c => wrapFunctional(c.default || c))
export const V002Billing = import('../../components/Paywall/v002/Billing.vue' /* webpackChunkName: "components/v002-billing" */).then(c => wrapFunctional(c.default || c))
export const V002FacebookReviews = import('../../components/Paywall/v002/FacebookReviews.vue' /* webpackChunkName: "components/v002-facebook-reviews" */).then(c => wrapFunctional(c.default || c))
export const V002PaywallBlock = import('../../components/Paywall/v002/PaywallBlock.vue' /* webpackChunkName: "components/v002-paywall-block" */).then(c => wrapFunctional(c.default || c))
export const V002PaywallOffer = import('../../components/Paywall/v002/PaywallOffer.vue' /* webpackChunkName: "components/v002-paywall-offer" */).then(c => wrapFunctional(c.default || c))
export const V003PaywallOffer = import('../../components/Paywall/v003/PaywallOffer.vue' /* webpackChunkName: "components/v003-paywall-offer" */).then(c => wrapFunctional(c.default || c))
export const V004PaywallOffer = import('../../components/Paywall/v004/PaywallOffer.vue' /* webpackChunkName: "components/v004-paywall-offer" */).then(c => wrapFunctional(c.default || c))
export const V005Billing = import('../../components/Paywall/v005/Billing.vue' /* webpackChunkName: "components/v005-billing" */).then(c => wrapFunctional(c.default || c))
export const V005PaywallBlock = import('../../components/Paywall/v005/PaywallBlock.vue' /* webpackChunkName: "components/v005-paywall-block" */).then(c => wrapFunctional(c.default || c))
export const V005PaywallOffer = import('../../components/Paywall/v005/PaywallOffer.vue' /* webpackChunkName: "components/v005-paywall-offer" */).then(c => wrapFunctional(c.default || c))
export const Achieve5xFasterChart = import('../../components/Achieve5xFasterChart.vue' /* webpackChunkName: "components/achieve5x-faster-chart" */).then(c => wrapFunctional(c.default || c))
export const Age = import('../../components/Age.vue' /* webpackChunkName: "components/age" */).then(c => wrapFunctional(c.default || c))
export const BigWhy = import('../../components/BigWhy.vue' /* webpackChunkName: "components/big-why" */).then(c => wrapFunctional(c.default || c))
export const Card = import('../../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const Choice = import('../../components/Choice.vue' /* webpackChunkName: "components/choice" */).then(c => wrapFunctional(c.default || c))
export const Email = import('../../components/Email.vue' /* webpackChunkName: "components/email" */).then(c => wrapFunctional(c.default || c))
export const EmailAndPhone = import('../../components/EmailAndPhone.vue' /* webpackChunkName: "components/email-and-phone" */).then(c => wrapFunctional(c.default || c))
export const Footer = import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FunnelComputedText = import('../../components/FunnelComputedText.vue' /* webpackChunkName: "components/funnel-computed-text" */).then(c => wrapFunctional(c.default || c))
export const GreatMotivation = import('../../components/GreatMotivation.vue' /* webpackChunkName: "components/great-motivation" */).then(c => wrapFunctional(c.default || c))
export const Header = import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Heart = import('../../components/Heart.vue' /* webpackChunkName: "components/heart" */).then(c => wrapFunctional(c.default || c))
export const Height = import('../../components/Height.vue' /* webpackChunkName: "components/height" */).then(c => wrapFunctional(c.default || c))
export const List = import('../../components/List.vue' /* webpackChunkName: "components/list" */).then(c => wrapFunctional(c.default || c))
export const Loader = import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const MultiChoice = import('../../components/MultiChoice.vue' /* webpackChunkName: "components/multi-choice" */).then(c => wrapFunctional(c.default || c))
export const MultipleBlocks = import('../../components/MultipleBlocks.vue' /* webpackChunkName: "components/multiple-blocks" */).then(c => wrapFunctional(c.default || c))
export const Name = import('../../components/Name.vue' /* webpackChunkName: "components/name" */).then(c => wrapFunctional(c.default || c))
export const Note = import('../../components/Note.vue' /* webpackChunkName: "components/note" */).then(c => wrapFunctional(c.default || c))
export const OfferInfo = import('../../components/OfferInfo.vue' /* webpackChunkName: "components/offer-info" */).then(c => wrapFunctional(c.default || c))
export const PageProgress = import('../../components/PageProgress.vue' /* webpackChunkName: "components/page-progress" */).then(c => wrapFunctional(c.default || c))
export const Rating = import('../../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => wrapFunctional(c.default || c))
export const Slider = import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const StepsDivider = import('../../components/StepsDivider.vue' /* webpackChunkName: "components/steps-divider" */).then(c => wrapFunctional(c.default || c))
export const Success = import('../../components/Success.vue' /* webpackChunkName: "components/success" */).then(c => wrapFunctional(c.default || c))
export const TextBlock = import('../../components/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => wrapFunctional(c.default || c))
export const Weight = import('../../components/Weight.vue' /* webpackChunkName: "components/weight" */).then(c => wrapFunctional(c.default || c))
export const WeightChart = import('../../components/WeightChart.vue' /* webpackChunkName: "components/weight-chart" */).then(c => wrapFunctional(c.default || c))
export const CertificatesBlocks = import('../../components/Coaches/CertificatesBlocks.vue' /* webpackChunkName: "components/certificates-blocks" */).then(c => wrapFunctional(c.default || c))
export const CoachesCarousel = import('../../components/Coaches/CoachesCarousel.vue' /* webpackChunkName: "components/coaches-carousel" */).then(c => wrapFunctional(c.default || c))
export const FakeChat = import('../../components/Users/FakeChat.vue' /* webpackChunkName: "components/fake-chat" */).then(c => wrapFunctional(c.default || c))
export const UserFeedbackBlock = import('../../components/Users/UserFeedbackBlock.vue' /* webpackChunkName: "components/user-feedback-block" */).then(c => wrapFunctional(c.default || c))
export const UserResults = import('../../components/Users/UserResults.vue' /* webpackChunkName: "components/user-results" */).then(c => wrapFunctional(c.default || c))
export const AnimatedButton = import('../../components/_atoms/AnimatedButton.vue' /* webpackChunkName: "components/animated-button" */).then(c => wrapFunctional(c.default || c))
export const BigInput = import('../../components/_atoms/BigInput.vue' /* webpackChunkName: "components/big-input" */).then(c => wrapFunctional(c.default || c))
export const Button = import('../../components/_atoms/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const Checkbox = import('../../components/_atoms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => wrapFunctional(c.default || c))
export const ListOfText = import('../../components/_atoms/ListOfText.vue' /* webpackChunkName: "components/list-of-text" */).then(c => wrapFunctional(c.default || c))
export const SubTitle = import('../../components/_atoms/SubTitle.vue' /* webpackChunkName: "components/sub-title" */).then(c => wrapFunctional(c.default || c))
export const TextareaAutosize = import('../../components/_atoms/TextareaAutosize.vue' /* webpackChunkName: "components/textarea-autosize" */).then(c => wrapFunctional(c.default || c))
export const Title = import('../../components/_atoms/Title.vue' /* webpackChunkName: "components/title" */).then(c => wrapFunctional(c.default || c))
