
import { mapGetters, mapState } from 'vuex'
import CoachesData from '@/components/_mixins/CoachesData'

export default {
  name: 'DefaultLayout',
  mixins: [CoachesData],
  data() {
    return {
      has_page_animation: false
    }
  },
  computed: {
    ...mapGetters('design', ['page_progress', 'header']),
    ...mapGetters('funnel', ['steps']),
    ...mapState('plan', ['defaultPlanIds', 'defaultCouponId'])
  },

  async mounted() {
    this.$logEvent({
      amp: {
        type: 'Funnel Visited',
        properties: {
          funnel_id: this.$store.getters['funnel/version'],
          url: window.location.href,
          ...(this.$exp.$variantIndexes
            ? {
                [`experiment_${this.$exp.experimentID}`]:
                  this.$exp.$variantIndexes[0]
              }
            : {})
        },
        user_properties: {
          funnel_id: this.$store.getters['funnel/version']
        },
        timestamp: Date.now() - 1000
      }
    })
    await this.$nextTick()
    setTimeout(() => {
      this.has_page_animation = true
    }, 400)

    this.getPlans()
    const initial_funnel = await this.getCoaches()
    this.$store.dispatch('funnel/setSteps', {
      query: this.$route.query,
      meta: this.steps?.meta,
      ...(this.steps?.experiments
        ? { experiments: this.steps.experiments }
        : {}),
      ...initial_funnel
    })
  },
  methods: {
    async getPlans() {
      await this.$recurlyLoad()
      const plans =
        (this?.planIds?.length ? this.planIds : false) ||
        (this?.defaultPlanIds?.length ? this.defaultPlanIds : false)

      await this.$recurlyPlansLoad({
        plans,
        coupon: this.couponId
      })

      await this.$paypalLoad()
    },

    async getCoaches() {
      const coaches = await this.$store.dispatch('funnel/loadCoaches', {
        coach_id: this.$route?.query?.coach_id
      })

      const coach_id = Object.keys(coaches)[0]

      this.$logEvent({
        amp: {
          user_properties: {
            funnel_id: this.$store.getters['funnel/version'],
            coach: coaches[coach_id]?.name,
            coach_ids: [coach_id]
          },
          timestamp: Date.now() - 1000
        }
      })

      return {
        initial_funnel: {
          ...this.$store.state.user.initial_funnel,
          coach_id: coach_id
        }
      }
    },

    async skipFunnel() {
      await this.$store.dispatch('funnel/setSteps', {
        biological_sex: 'Female',
        name: 'Skip Funnel',
        age: 45,
        height: 168,
        weight: 60.781328,
        goal_weight: 55.338224,
        what_is_your_health_goal: ['lose_weight'],
        medical_condition: ['high_blood_pressure'],
        keto_familiarity: 'beginner',
        have_you_attempted_any_of_the_following_to_lose_weight: [
          'restrictive_diets',
          'gym_or_exercise'
        ],
        lose_weight_on_your_own: 'yes',
        are_you_familiar_with_health_and_wellness_coaching: 'yes',
        aspects_of_coaching: ['providing_non-judgemental_unwavering_support'],
        family_struggled_with_their_weight: ['mother'],
        last_time_ideal_weight: 'more_than_3_years_ago',
        life_events_led_to_weight_gain: ['medication_or_hormonal_disorder'],
        glasses_of_water: '3_6_glasses',
        consume_alcohol: '2-4_times_per_week',
        meals_per_day: '4_meals',
        meals_same_time: 'no',
        best_describes: 'i_have_some_healthy_habits',
        quality_of_your_sleep: 'it_varies',
        how_many_hours_do_you_sleep: '8-9_hours',
        activity_level: 2,
        how_busy_are_you_on_an_average_day: 'im_not_too_busy',
        physical_limitations: 'no',
        how_often_do_you_exercise: '3-5_times_per_week',
        lose_weight_without_working_out: 'no',
        eating_disorder: 'yes',
        do_you_cook: 'cook_a_lot',
        food_allergies_or_intolerances: ['wheat_gluten'],
        dietary_restrictions: 'yes',
        prescription_medications: 'yes',
        rate_your_overall_health: 4,
        my_family_is_concerned_about_my_health: 4,
        'my_weight_impacts_my_self-esteem': 4,
        main_motivation: ['gain_confidence'],
        how_motivated_are_you_to_achieve_your_health_goals:
          'im_okay_and_looking_forward_to_it',
        how_quickly_would_you_like_to_achieve_your_goal: 'slow_and_steady',
        biggest_challenges_to_living_a_healthy_lifestyle: [
          'i_struggle_with_motivation'
        ],
        big_goal: 'ill_set_up_it_later_with_my_coach'
      })

      await this.$nextTick()

      const { query } = this.$route
      this.$router.push({
        path: `/s/registration`,
        query: { ...query, lead_id: this.$getInstance() }
      })
    }
  }
}
