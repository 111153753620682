import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('funnel', ['coaches'])
  },
  data() {
    return {
      coach: null
    }
  },
  methods: {
    getCoachByID(id) {
      return this.coaches[id]
    }
  },
  watch: {
    coaches: {
      handler() {
        this.coach = this.getCoachByID(
          this.$store.state.user.initial_funnel.coach_id
        )
      },
      // force eager callback execution
      immediate: true
    }
  },
  async mounted() {}
}
