export const UNIT_SYSTEM = Object.freeze({
  IMPERIAL: 'imperial',
  METRIC: 'metric'
})

export const KG_TO_LBS = 0.453592
export const CM_TO_FT = 0.0328

export const PAYMENT_GATES = {
  CARD: 'card',
  PAYPAL: 'paypal'
}
