// initial state
import { httpsCallable } from 'firebase/functions'
import { collection, doc, setDoc } from 'firebase/firestore'

export const state = () => {
  return {
    version: null,
    config: null,
    sign_in_link: null,
    steps: {},
    current_step: {},
    coaches: {}
  }
}

// getters
export const getters = {
  config({ config }) {
    return config
  },
  version({ version }) {
    return version
  },
  steps({ steps }) {
    return steps
  },
  sign_in_link({ sign_in_link }) {
    return sign_in_link
  },
  coaches({ coaches }) {
    return coaches
  }
}

export const actions = {
  async setVersion({ commit }, version) {
    commit('setVersion', version)
    const funnel = await import(`~/static/_db/funnels/${version}`)
    await commit('setConfig', funnel)

    return funnel
  },
  async setDefaultState({ commit }, data) {
    const funnel = await import(`~/static/_db/funnels/${data.version}`)

    commit('setConfig', funnel)

    return commit('setDefaultState', { ...data })
  },
  async setSteps({ state, commit }, step) {
    commit('setSteps', step)

    return setDoc(
      doc(
        collection(this.$firestore, '/attribution/v0/funnels'),
        this.$ampGetInstance()
      ),
      {
        funnel_version: state.version,
        ...step
      },
      { merge: true }
    )
  },
  async loadCoaches(
    { commit, rootState, dispatch },
    { coach_id = false, limit = 8 } = {}
  ) {
    const getCoaches = httpsCallable(
      this.$functions,
      'products-v0-programs-coaches-getFunnelCoaches'
    )

    const request_body = {
      program_id: rootState.user.initial_funnel.program_id,
      limit
    }
    const requests = []

    if (coach_id)
      requests.push(
        getCoaches({ ...request_body, coach_id, limit: 1 }).catch(e => {
          console.log(e)
        })
      )

    requests.push(await getCoaches(request_body))

    const response = await Promise.all(requests).then(responses => {
      return responses.reduce((prev, next) => {
        if (next && next.data && next.data.coaches)
          next.data.coaches.forEach(item =>
            !prev[item.id] ? (prev[item.id] = item) : false
          )
        return prev
      }, {})
    })

    dispatch('user/setCoach', Object.keys(response)[0], { root: true })
    commit('setCoaches', response)

    return response
  },
  async setCurrentStep({ state, commit, dispatch }, step) {
    const header = {
      color: step.component_id === 'StepsDivider' ? 'white' : 'black',
      ...step.header
    }

    dispatch('design/setHeader', header, { root: true })
    dispatch(
      'design/setPageProgress',
      Math.round(
        ((state.config.screens.indexOf(step) * 100) /
          state.config.screens.length) *
          100
      ) / 100,
      { root: true }
    )

    return commit('setCurrentStep', step)
  }
}

export const mutations = {
  setVersion(state, version) {
    state.version = version
  },
  setCoaches(state, coaches) {
    state.coaches = coaches
  },
  setConfig(state, { funnel }) {
    state.config = funnel
  },
  setSignInLink(state, link) {
    state.sign_in_link = link
  },
  setSteps(state, step) {
    state.steps = { ...state.steps, ...step }
  },
  setCurrentStep(state, step) {
    state.current_step = { ...step }
  },
  setDefaultState(state, data) {
    state = { ...state, ...data }
  }
}
