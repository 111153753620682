// initial state

export const state = () => {
  return {
    header: {},
    page_progress: null
  }
}

// getters
export const getters = {
  header({ header }) {
    return header
  },
  page_progress({ page_progress }) {
    return page_progress
  }
}

export const actions = {
  async setHeader({ state, commit }, header) {
    return commit('setHeader', header)
  },
  async setPageProgress({ state, commit }, page_progress) {
    return commit('setPageProgress', page_progress)
  }
}

export const mutations = {
  setHeader(state, header) {
    state.header = header
  },
  setPageProgress(state, page_progress) {
    state.page_progress = page_progress
  }
}
