import Vue from 'vue'

async function load(client_id) {
  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.paypal.com/sdk/js?client-id=${client_id}&vault=true&intent=tokenize`

  const firstScript = document.getElementsByTagName('script')[0]
  await Promise.all([
    new Promise((resolve, reject) => {
      script.onload = resolve
      script.onerror = reject
      firstScript.parentNode.insertBefore(script, firstScript)
    })
  ])
  return window.paypal
}

const VueLazyPaypal = {
  install(_Vue, { client_id }) {
    Object.defineProperty(_Vue.prototype, '$paypal', {
      enumerable: true,
      get: () => window.paypal
    })

    let loading = null
    Object.defineProperty(_Vue.prototype, '$paypalLoad', {
      enumerable: true,
      get: () => async () => {
        if (window.paypal) return window.paypal
        if (loading) return loading
        try {
          loading = load(client_id)
          return await loading
        } catch (err) {
          console.error(err)
          throw err
        } finally {
          loading = null
        }
      }
    })
  }
}

export default ({ $config }) =>
  Vue.use(VueLazyPaypal, {
    client_id: $config.paypal_client_id
  })
