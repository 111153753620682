import { PAYMENT_GATES } from '@/enums'
import { httpsCallable } from 'firebase/functions'

export const state = () => ({
  defaultPlanIds: ['monthly-149-7d-1'],
  defaultCouponId: null,
  planLoading: {},
  plan: {},
  defaultPaymentGates: [PAYMENT_GATES.CARD]
})

export const actions = {
  async load({ state, commit }, planId) {
    planId = planId || state.defaultPlanId
    if (state.plan[planId]) return state.plan[planId]
    if (state.planLoading[planId]) return state.planLoading[planId]

    const promise = new Promise(async (resolve, reject) => {
      const getPlan = httpsCallable(this.$functions, 'getPlan')
      let tries = 3
      while (tries--) {
        try {
          const { data } = await getPlan({
            plan_code: planId,
            payment_provider: 'recurly'
          })
          commit('onPlanLoaded', { planId, plan: data })
          return resolve(data)
        } catch (err) {
          console.log(err)
          if (!tries) {
            commit('onPlanLoaded', { planId, plan: null })
            return reject(err)
          }
        }
      }
    })
    commit('onStartLoading', { planId, promise })
    return promise
  },
  defaultPlanIds({ state, commit }, { planIds }) {
    commit('setDefaultPlanIds', { planIds })
    // commit('user/setUserPlanIds', { planIds }, { root: true })
  }
}

export const mutations = {
  setDefaultPlanIds(state, { planIds }) {
    if (planIds) state.defaultPlanIds = [...planIds] // default plan must be set
  },
  setDefaultCouponId(state, { couponId }) {
    state.defaultCouponId = couponId
  },
  onPlanLoaded(state, { planId, plan }) {
    state.plan[planId] = plan
    state.planLoading[planId] = null
  },
  onStartLoading(state, { planId, promise }) {
    state.planLoading[planId] = promise
  },
  setDefaultPaymentGates(state, paymentGates) {
    state.defaultPaymentGates = [...paymentGates]
  }
}
