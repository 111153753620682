export default (ctx, inject) => {
  // make lazy component loaders available for hydratableLoader
  // lets set all component options of components which were detected by nuxt/components including its loaders as prop of each component

  // now we are going to inject all the dynamic webpack imports to each component as single property with its own chunk
  const loaders = {
      LazyNuxtDynamic: () => import('../node_modules/@blokwise/dynamic/src/components/Dynamic.vue' /* webpackChunkName: "components/nuxt-dynamic" */).then(c => c.default || c),
      LazyV005StartPage: () => import('../components/funnels/v005/StartPage.vue' /* webpackChunkName: "components/v005-start-page" */).then(c => c.default || c),
      LazyV006StartPage: () => import('../components/funnels/v006/StartPage.vue' /* webpackChunkName: "components/v006-start-page" */).then(c => c.default || c),
      LazyBilling: () => import('../components/Paywall/Billing.vue' /* webpackChunkName: "components/billing" */).then(c => c.default || c),
      LazyBillingTabs: () => import('../components/Paywall/BillingTabs.vue' /* webpackChunkName: "components/billing-tabs" */).then(c => c.default || c),
      LazyMoneyBack: () => import('../components/Paywall/MoneyBack.vue' /* webpackChunkName: "components/money-back" */).then(c => c.default || c),
      LazyPaywallBlock: () => import('../components/Paywall/PaywallBlock.vue' /* webpackChunkName: "components/paywall-block" */).then(c => c.default || c),
      LazyPaywallOffer: () => import('../components/Paywall/PaywallOffer.vue' /* webpackChunkName: "components/paywall-offer" */).then(c => c.default || c),
      LazySecureImages: () => import('../components/Paywall/SecureImages.vue' /* webpackChunkName: "components/secure-images" */).then(c => c.default || c),
      LazySuccessPayment: () => import('../components/Paywall/SuccessPayment.vue' /* webpackChunkName: "components/success-payment" */).then(c => c.default || c),
      LazyV002Billing: () => import('../components/Paywall/v002/Billing.vue' /* webpackChunkName: "components/v002-billing" */).then(c => c.default || c),
      LazyV002FacebookReviews: () => import('../components/Paywall/v002/FacebookReviews.vue' /* webpackChunkName: "components/v002-facebook-reviews" */).then(c => c.default || c),
      LazyV002PaywallBlock: () => import('../components/Paywall/v002/PaywallBlock.vue' /* webpackChunkName: "components/v002-paywall-block" */).then(c => c.default || c),
      LazyV002PaywallOffer: () => import('../components/Paywall/v002/PaywallOffer.vue' /* webpackChunkName: "components/v002-paywall-offer" */).then(c => c.default || c),
      LazyV003PaywallOffer: () => import('../components/Paywall/v003/PaywallOffer.vue' /* webpackChunkName: "components/v003-paywall-offer" */).then(c => c.default || c),
      LazyV004PaywallOffer: () => import('../components/Paywall/v004/PaywallOffer.vue' /* webpackChunkName: "components/v004-paywall-offer" */).then(c => c.default || c),
      LazyV005Billing: () => import('../components/Paywall/v005/Billing.vue' /* webpackChunkName: "components/v005-billing" */).then(c => c.default || c),
      LazyV005PaywallBlock: () => import('../components/Paywall/v005/PaywallBlock.vue' /* webpackChunkName: "components/v005-paywall-block" */).then(c => c.default || c),
      LazyV005PaywallOffer: () => import('../components/Paywall/v005/PaywallOffer.vue' /* webpackChunkName: "components/v005-paywall-offer" */).then(c => c.default || c),
      LazyAchieve5xFasterChart: () => import('../components/Achieve5xFasterChart.vue' /* webpackChunkName: "components/achieve5x-faster-chart" */).then(c => c.default || c),
      LazyAge: () => import('../components/Age.vue' /* webpackChunkName: "components/age" */).then(c => c.default || c),
      LazyBigWhy: () => import('../components/BigWhy.vue' /* webpackChunkName: "components/big-why" */).then(c => c.default || c),
      LazyCard: () => import('../components/Card.vue' /* webpackChunkName: "components/card" */).then(c => c.default || c),
      LazyChoice: () => import('../components/Choice.vue' /* webpackChunkName: "components/choice" */).then(c => c.default || c),
      LazyEmail: () => import('../components/Email.vue' /* webpackChunkName: "components/email" */).then(c => c.default || c),
      LazyEmailAndPhone: () => import('../components/EmailAndPhone.vue' /* webpackChunkName: "components/email-and-phone" */).then(c => c.default || c),
      LazyFooter: () => import('../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => c.default || c),
      LazyFunnelComputedText: () => import('../components/FunnelComputedText.vue' /* webpackChunkName: "components/funnel-computed-text" */).then(c => c.default || c),
      LazyGreatMotivation: () => import('../components/GreatMotivation.vue' /* webpackChunkName: "components/great-motivation" */).then(c => c.default || c),
      LazyHeader: () => import('../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => c.default || c),
      LazyHeart: () => import('../components/Heart.vue' /* webpackChunkName: "components/heart" */).then(c => c.default || c),
      LazyHeight: () => import('../components/Height.vue' /* webpackChunkName: "components/height" */).then(c => c.default || c),
      LazyList: () => import('../components/List.vue' /* webpackChunkName: "components/list" */).then(c => c.default || c),
      LazyLoader: () => import('../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => c.default || c),
      LazyMultiChoice: () => import('../components/MultiChoice.vue' /* webpackChunkName: "components/multi-choice" */).then(c => c.default || c),
      LazyMultipleBlocks: () => import('../components/MultipleBlocks.vue' /* webpackChunkName: "components/multiple-blocks" */).then(c => c.default || c),
      LazyName: () => import('../components/Name.vue' /* webpackChunkName: "components/name" */).then(c => c.default || c),
      LazyNote: () => import('../components/Note.vue' /* webpackChunkName: "components/note" */).then(c => c.default || c),
      LazyOfferInfo: () => import('../components/OfferInfo.vue' /* webpackChunkName: "components/offer-info" */).then(c => c.default || c),
      LazyPageProgress: () => import('../components/PageProgress.vue' /* webpackChunkName: "components/page-progress" */).then(c => c.default || c),
      LazyRating: () => import('../components/Rating.vue' /* webpackChunkName: "components/rating" */).then(c => c.default || c),
      LazySlider: () => import('../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => c.default || c),
      LazyStepsDivider: () => import('../components/StepsDivider.vue' /* webpackChunkName: "components/steps-divider" */).then(c => c.default || c),
      LazySuccess: () => import('../components/Success.vue' /* webpackChunkName: "components/success" */).then(c => c.default || c),
      LazyTextBlock: () => import('../components/TextBlock.vue' /* webpackChunkName: "components/text-block" */).then(c => c.default || c),
      LazyWeight: () => import('../components/Weight.vue' /* webpackChunkName: "components/weight" */).then(c => c.default || c),
      LazyWeightChart: () => import('../components/WeightChart.vue' /* webpackChunkName: "components/weight-chart" */).then(c => c.default || c),
      LazyCertificatesBlocks: () => import('../components/Coaches/CertificatesBlocks.vue' /* webpackChunkName: "components/certificates-blocks" */).then(c => c.default || c),
      LazyCoachesCarousel: () => import('../components/Coaches/CoachesCarousel.vue' /* webpackChunkName: "components/coaches-carousel" */).then(c => c.default || c),
      LazyFakeChat: () => import('../components/Users/FakeChat.vue' /* webpackChunkName: "components/fake-chat" */).then(c => c.default || c),
      LazyUserFeedbackBlock: () => import('../components/Users/UserFeedbackBlock.vue' /* webpackChunkName: "components/user-feedback-block" */).then(c => c.default || c),
      LazyUserResults: () => import('../components/Users/UserResults.vue' /* webpackChunkName: "components/user-results" */).then(c => c.default || c),
      LazyAnimatedButton: () => import('../components/_atoms/AnimatedButton.vue' /* webpackChunkName: "components/animated-button" */).then(c => c.default || c),
      LazyBigInput: () => import('../components/_atoms/BigInput.vue' /* webpackChunkName: "components/big-input" */).then(c => c.default || c),
      LazyButton: () => import('../components/_atoms/Button.vue' /* webpackChunkName: "components/button" */).then(c => c.default || c),
      LazyCheckbox: () => import('../components/_atoms/Checkbox.vue' /* webpackChunkName: "components/checkbox" */).then(c => c.default || c),
      LazyListOfText: () => import('../components/_atoms/ListOfText.vue' /* webpackChunkName: "components/list-of-text" */).then(c => c.default || c),
      LazySubTitle: () => import('../components/_atoms/SubTitle.vue' /* webpackChunkName: "components/sub-title" */).then(c => c.default || c),
      LazyTextareaAutosize: () => import('../components/_atoms/TextareaAutosize.vue' /* webpackChunkName: "components/textarea-autosize" */).then(c => c.default || c),
      LazyTitle: () => import('../components/_atoms/Title.vue' /* webpackChunkName: "components/title" */).then(c => c.default || c)
  }

  const prefixes = ["nuxt"]

  inject("nuxtDynamic", { loaders, prefixes });
};
