export const strict = true

const getFBMetadata = (current_params, req) => {
  // console.log('getFBMetadata', { current_params })
  // if (!current_params || !Object.keys(current_params).length) return 0

  const event = {}
  if (req && req.headers) {
    const ip =
      req.headers['x-appengine-user-ip'] ||
      req.headers['x-forwarded-for'] ||
      req.connection.remoteAddress ||
      ''
    const user_agent = req.headers['user-agent']
    event.ip = ip
    event.user_agent = user_agent
    // event.source_url = 'https://' + req.headers.host + req.originalUrl
    event.source_url = 'https://join.fjor.health' + req.originalUrl
  }
  const {
    utm_content,
    pid,
    af_prt,
    utm_source,
    utm_campaign,
    utm_medium,
    utm_term,
    ppid,
    fbclid
  } = current_params

  if (utm_content) {
    event.af_adset = utm_content
    event.utm_content = utm_content
  }

  event.pid = pid || 'FJOR_Funnel'

  if (af_prt) event.af_prt = af_prt

  if (utm_source && !utm_source.includes('email'))
    event.media_source = utm_source

  if (utm_campaign && !utm_campaign.includes('remarketing')) {
    event.c = utm_campaign
    event.af_campaign = utm_campaign
  }

  if (utm_term) event.af_ad = utm_term

  if (utm_medium && !utm_medium.includes('remarketing'))
    event.utm_medium = utm_medium

  if (ppid)
    event.af_r = `https://apps.apple.com/app/FJORWellness/id1616511477?ppid=${ppid}`

  // Cookie starts
  const getCookie = name => {
    let matches =
      req.headers && req.headers.cookie
        ? req.headers.cookie.match(
            new RegExp(
              '(?:^|; )' +
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                '=([^;]*)'
            )
          )
        : false
    return matches ? decodeURIComponent(matches[1]) : undefined
  }
  let fbp = getCookie('_fbp')
  let fbc = getCookie('_fbc')
  if (!fbp)
    fbp =
      'fb.1.' +
      Math.round(Date.now()) +
      '.' +
      Math.round(Math.random() * 100000 * 100000)
  if (fbclid && !fbc) fbc = 'fb.1.' + Math.round(Date.now()) + '.' + fbclid

  if (fbc) event.fbc = fbc

  if (fbp) event.fbp = fbp

  // console.log('getFBMetadata', { event })

  return event
}

const getUserFunnel = async (lead_id, projectId) => {
  const getValue = (id, value) => {
    let resp = {}

    if (value.doubleValue) {
      resp[id] = value.doubleValue
    } else if (value.stringValue) {
      resp[id] = value.stringValue
    } else if (value.arrayValue && value.arrayValue.values) {
      resp[id] = value.arrayValue.values.map(item => item.stringValue)
    } else if (value.mapValue && Object.keys(value.mapValue).length) {
      resp = {
        ...resp,
        [id]: Object.entries(value.mapValue.fields).reduce(
          (prev, [field_id, field_value]) => {
            return { ...prev, ...getValue(field_id, field_value) }
          },
          {}
        )
      }
    } else if (value.integerValue) {
      resp[id] = Number(value.integerValue)
    } else {
      console.log(id, value)
    }

    return resp
  }

  return fetch(
    `https://firestore.googleapis.com/v1/projects/${projectId}/databases/(default)/documents/attribution/v0/funnels/${lead_id}`
  )
    .then(response => response.json())
    .then(data =>
      data?.fields
        ? Object.entries(data.fields).reduce((prev, [id, value]) => {
            return { ...prev, ...getValue(id, value) }
          }, {})
        : false
    )
}

const getFBFunnel = query => {
  if (query?.utm_campaign && query.utm_campaign.includes('LowPrice_test')) {
    return `v_0.1.0-paywall_v_0.0.5`
  }

  return false
}

const getDefaultFBFunnel = query => {
  if (query?.utm_source?.includes('Facebook-ads')) {
    return `v_0.1.1-paywall_v_0.0.4`
  }

  return false
}

export const actions = {
  async nuxtServerInit({ commit, dispatch }, { $config, route, req, $exp }) {
    const current_exp = $exp.$activeVariants?.[0]
    const version =
      route?.query?.funnel_id ||
      getFBFunnel(route?.query) ||
      getDefaultFBFunnel(route?.query) ||
      current_exp?.funnel_id ||
      $config.default_funnel_version
    const lead_id = route?.query?.lead_id
    const request = [
      dispatch('user/setFunnel', version),
      dispatch('funnel/setVersion', version)
    ]

    if (lead_id)
      request.push(getUserFunnel(lead_id, $config.firebase.projectId))
    else request.push()

    console.log('version', current_exp, version)

    const [user_version, { funnel }, fields] = await Promise.all(request)

    const experiments = current_exp?.experiments || funnel?.experiments
    const no_sing_in_link =
      current_exp?.no_sing_in_link || funnel?.no_sing_in_link
    const metaData = await getFBMetadata(route.query, req)

    await commit('funnel/setSteps', {
      email: route?.query?.email,
      biological_sex: 'Female', // default gender
      ...(experiments ? { experiments } : {}),
      ...(no_sing_in_link ? { no_sing_in_link: true } : {}),
      ...(fields ? fields : {}),
      ...(metaData ? { meta: metaData } : {})
    })

    const plan =
      route?.query?.plan ||
      current_exp?.default_plan_ids ||
      funnel?.plans ||
      $config.default_plan_ids

    if (plan) {
      // preload default plan
      if (Array.isArray(plan))
        await dispatch('plan/defaultPlanIds', {
          planIds: [...plan]
        })
      else if (typeof plan === 'string')
        await dispatch('plan/defaultPlanIds', {
          planIds: [plan]
        })
    }
  }
}
