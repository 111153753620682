import { initializeApp, getApps, getApp } from 'firebase/app'
import {
  initializeAuth
  // indexedDBLocalPersistence,
  // browserLocalPersistence,
  // browserSessionPersistence
} from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'

export default (ctx, inject) => {
  const apps = getApps()
  if (!apps.length) initializeApp(ctx.$config.firebase)

  inject(
    'auth',
    initializeAuth(getApp(), {
      // persistence: [
      //   indexedDBLocalPersistence,
      //   browserLocalPersistence,
      //   browserSessionPersistence
      // ],
      // popupRedirectResolver: undefined
    })
  )
  inject('firestore', getFirestore())
  inject('functions', getFunctions())
  ctx.$firestore = getFirestore()

  // const functions = getFunctions(getApp())
  // connectFunctionsEmulator(functions, 'localhost', 5001)
}
