export default ctx => {
  ;(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r
    ;(i[r] =
      i[r] ||
      function () {
        ;(i[r].q = i[r].q || []).push(arguments)
      }),
      (i[r].l = 1 * new Date())
    ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
    a.async = 1
    a.src = g
    m.parentNode.insertBefore(a, m)
  })(
    window,
    document,
    'script',
    'https://www.google-analytics.com/analytics.js',
    'ga'
  )

  window.ga('create', 'UA-161488173-5', 'auto')
  //

  // send the first page hit after you are assigned the experiment
  setTimeout(function () {
    window.ga('set', 'userId', ctx.$ampGetInstance())
    window.ga('send', 'pageview')
  }, 1000)
}
