// initial state
export const state = data => {
  return {
    user: null,
    is_metric: false,
    personal_plan: {},
    initial_funnel: {
      version: 'v0' || null,
      coach_id: null,
      program_id: 'kbSBdQQT3y2QKuXcubBe' || null
    }
  }
}

// getters
export const getters = {
  user(state) {
    return state.user
  },
  personal_plan(state) {
    return state.personal_plan
  },
  initial_funnel(state) {
    return state.initial_funnel
  },
  is_metric(state) {
    return state.is_metric
  }
}

// actions
export const actions = {
  async setCoach({ commit }, coach_id) {
    return commit('setUserCoach', coach_id)
  },
  async setFunnel({ commit }, funnel_id) {
    console.log({ funnel_id })
    return commit('setFunnel', funnel_id)
  }
}

// mutations
export const mutations = {
  setUserPlanIds(state, { planIds }) {
    state.initial_funnel.form.planIds = [...planIds]
  },
  setUserCoach(state, coach_id) {
    state.initial_funnel.coach_id = coach_id
  },
  setFunnel(state, funnel_id) {
    state.initial_funnel.version = funnel_id
  },
  setUser(state, user) {
    state.user = { ...state.user, ...user }
  }
}
