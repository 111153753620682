export default (ctx, inject) => {
  inject('logEvent', event => {
    if (event.amp) {
      ctx.$ampLogEvent({
        event: event.amp.type,
        properties: event.amp.properties,
        timestamp: event.amp.timestamp,
        user_properties: event.amp.user_properties
      })
    }
  })
  inject('getInstance', () => {
    return ctx.$ampGetInstance()
  })
}
